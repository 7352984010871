<template>
  <!-- 页面name：结束反馈 -->
  <div class="contain">
    <div class="form-header-btn">
      <el-page-header @back="goBack" :content="'结束反馈'">
        <template slot="title">
          <i class="el-icon-arrow-left"></i>返回
        </template>
      </el-page-header>
    </div>
    <div class="form">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-position="right"
        :label-width="labelWidth"
        class="demo-ruleForm"
      >
        <el-form-item label="结束时间:" prop="feedbackTime">
          <el-date-picker
            type="datetime"
            placeholder="请选择日期时间"
            v-model="ruleForm.feedbackTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 100%"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="目前情况:" prop="feedbackCondition">
          <el-input
            v-model="ruleForm.feedbackCondition"
            type="textarea"
            class="elInput"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="form-btn">
      <el-button class="goback" @click="onGoBack">返回</el-button>
      <el-button class="submit" @click="onSubmit('ruleForm')">提交</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        feedbackTime: "", //结束时间
        feedbackCondition: "",
      },

      rules: {
        feedbackTime: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
        feedbackCondition: [
          { required: true, message: "请填写目前情况", trigger: "change" },
        ],
      },
      lookId: "",
      pickerOptions: {
        disabledDate(times) {
          return times.getTime() > Date.now();
        },
      },

      labelWidth: '120px',
    };
  },
  watch: {},
  mounted() {
    this.lookId = this.$route.query.lookId;

    window.addEventListener("resize",(e) => {
      if(e.target.innerWidth < 1300){
        this.labelWidth = '80px'
      } else {
        this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
    if(window.innerWidth < 1300){
      this.labelWidth = '80px'
    } else {
      this.labelWidth = (120 / 1920 * window.innerWidth) + 'px'
    }
  },
  methods: {
    // 今天以及今天以前的日期
    //   :picker-options="pickerOptions"
    // pickerOptions: {
    //   disabledDate(time) {
    //     let _now = Date.now();
    //     return time.getTime() < _now;
    //   },
    // },

    onGoBack() {
      this.goBack();
    },

    goBack() {
      this.$router.go(-1);
    },

    // 今结束反馈
    onSubmit() {
      console.log(this.ruleForm);
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let params = {
            id: this.lookId,
            feedbackTime: this.ruleForm.feedbackTime,
            feedbackCondition: this.ruleForm.feedbackCondition,
          };
          this.$api.getUpdateFeedback(params).then((res) => {
            if (res.data.code == 0) {
              // this.$message.success(res.data.msg);
              this.goBack();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  beforeDestroy(){
    window.removeEventListener("resize",(e) => {
      if(e.target.innerWidth < 1300){
        this.labelWidth = '80px'
      } else {
        this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload {
  width: 192px;
  height: 108px;
  line-height: 108px;
}
.contain {
  padding: 34px 30px 30px 30px;
  .form-header-btn{
    margin-bottom: 20px;
  }

  .form {
    width: 100%;
  }

  .form-btn {
    margin-top: 43px;
    display: flex;
    padding-left: 100px;

    .goback {
      display: block;
      width: 137px;
      height: 40px;
      background: #ff4444;
      box-shadow: 0px 3px 10px rgba(255, 68, 68, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    .cancel {
      width: 137px;
      height: 40px;
      border: 1px solid #b5bdcf;
      border-radius: 4px;
      font-size: 14px;
      line-height: 14px;
      color: #222222;
      opacity: 1;
    }

    .submit {
      display: block;
      width: 137px;
      height: 40px;
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }
}
</style>
